"use client";

import "mapbox-gl/dist/mapbox-gl.css";

import { useState, useEffect, useMemo, useCallback, useRef } from "react";

import { H } from "@highlight-run/next/client";

import {
  Map,
  GeolocateControl,
  Marker,
  Layer,
  Source,
  NavigationControl,
  Popup,
} from "react-map-gl";
import type {
  LngLatBoundsLike,
  LayerProps,
  MapRef,
  GeoJSONSource,
  MapMouseEvent,
  MapTouchEvent,
} from "react-map-gl";

import type { GeocoderLocation, Station } from "@/types";

import GeocoderControl from "@/components/map/geocoder-control";
import PinIcon from "@/components/map/pin-icon";

import { useStations, useStationsInRadius } from "@/hooks/api";
import { useMediaQuery } from "@/hooks/use-media-query";
import { useUrlState } from "@/hooks/query-state";

import tailwind from "@/lib/tailwind";

// https://github.com/sandstrom/country-bounding-boxes/blob/master/bounding-boxes.json#L117C25-L117C50
const NL_BOUNDING_BOX = [3.31, 50.8, 7.09, 53.51];

const MAPBOX_STYLE = "mapbox://styles/maher4ever/cm3eanejg003r01qpcfrkd3jf";

const clusterCountLayer: LayerProps = {
  id: "cluster-count",
  type: "symbol",
  source: "stations",
  filter: ["has", "point_count"],
  layout: {
    "text-field": "{point_count_abbreviated}",
    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
    "text-size": 14,
  },
};

const clusterLayer: LayerProps = {
  id: "clusters",
  type: "circle",
  source: "stations",
  filter: ["has", "point_count"],
  paint: {
    "circle-color": tailwind.theme.colors.slate[400],
    "circle-blur": 0.5,
    "circle-opacity": 0.75,
    "circle-radius": [
      "step",
      ["get", "point_count"],
      25,
      10,
      30,
      50,
      35,
      100,
      40,
    ],
  },
};

const stationsInRadiusCircleLayer: LayerProps = {
  id: "stations-in-radius-circle",
  type: "fill",
  source: "stations-in-radius",
  paint: {
    "fill-color": tailwind.theme.colors.sky[900],
    "fill-opacity": 0.1,
  },
};

interface StationsMapProps {
  className?: string;
  children?: React.ReactNode;
}

function StationsMap({ className, children }: StationsMapProps) {
  const geoControlRef = useRef<mapboxgl.GeolocateControl>(null);
  const inputFieldRef = useRef<HTMLInputElement | null>(null);

  const [map, setMap] = useState<MapRef | null>(null);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [marker, setMarker] = useState<GeoJSON.Position | undefined>(undefined);
  const [hoveredStation, setHoveredStation] = useState<Station | null>(null);

  const isDesktop = useMediaQuery("(min-width: 768px)");

  const { data: stations = [] } = useStations();
  const {
    selectedLocation,
    setSelectedLocation,
    selectedStation,
    setSelectedStation,
  } = useUrlState();
  const { stationsInRadiusArea, stationsInRadiusBounds } =
    useStationsInRadius();

  const unclusteredPointLayer = useMemo<LayerProps>(
    () => ({
      id: "unclustered-point",
      type: "symbol",
      source: "stations",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "icon-color": [
          "case",
          ["==", ["get", "_id"], ["literal", selectedStation?._id ?? ""]],
          tailwind.theme.colors.cyan[600],
          tailwind.theme.colors.zinc[700],
        ],
      },
      layout: {
        "icon-image": "gas-station-sdf",
        "icon-size": 0.4,
        "icon-anchor": "top",
      },
    }),
    [selectedStation?._id],
  );

  const changeLocation = useCallback(
    (location: GeocoderLocation) => {
      setSelectedLocation(location);
    },
    [setSelectedLocation],
  );

  const onLoad = useCallback(() => {
    // Disable one finger zoom
    // See https://stackoverflow.com/a/75774164
    map?.getMap().touchZoomRotate._tapDragZoom.disable();

    // Prevent the geolocate control from changing the zoom level
    // See https://stackoverflow.com/a/77638163
    if (geoControlRef.current) {
      geoControlRef.current._updateCamera = () => {};
    }

    // Store a reference to the input field
    inputFieldRef.current = document.getElementsByClassName(
      "mapboxgl-ctrl-geocoder--input",
    )[0] as HTMLInputElement;
  }, [map]);

  // Snapshot the canvas to Highlight.io when the map is done rendering
  const onIdle = useCallback(() => {
    const canvas = map?.getCanvas();
    if (canvas) {
      H.snapshot(canvas);
    }
  }, [map]);

  const onClick = useCallback(
    (event: MapMouseEvent) => {
      if (selectedStation) {
        setSelectedStation(null, { history: "replace" });
      }

      if (!event.features || event.features.length === 0) {
        return;
      }

      const feature = event.features[0];
      if (feature.layer?.id === clusterLayer.id) {
        const clusterId = feature.properties?.cluster_id;

        const mapboxSource = map?.getSource("stations") as GeoJSONSource;

        mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) {
            return;
          }

          map?.easeTo({
            center:
              feature.geometry.type === "Point"
                ? (feature.geometry.coordinates as [number, number])
                : undefined,
            zoom: zoom ?? undefined,
            duration: 500,
          });
        });
      } else if (feature.layer?.id === unclusteredPointLayer.id) {
        setSelectedStation(feature.properties as Station);
      }
    },
    [selectedStation, setSelectedStation, unclusteredPointLayer.id, map],
  );

  const onMouseEnter = useCallback(
    (event: MapMouseEvent | MapTouchEvent) => {
      setCursor("pointer");

      if (!event.features || event.features.length === 0) {
        return;
      }

      const feature = event.features[0];
      if (feature.layer?.id === unclusteredPointLayer.id) {
        setHoveredStation(feature.properties as Station);
      }
    },
    [unclusteredPointLayer.id],
  );

  const onMouseLeave = useCallback(() => {
    setCursor(undefined);
    setHoveredStation(null);
  }, []);

  const onGeocoderLocation = useCallback(
    (location: GeocoderLocation) => {
      changeLocation(location);

      // Hide keyboard on mobile
      inputFieldRef.current?.blur();
    },
    [changeLocation],
  );

  const markers = useMemo(() => {
    return {
      type: "FeatureCollection",
      features: stations.map((station) => ({
        type: "Feature",
        properties: station,
        geometry: {
          type: "Point",
          coordinates: [station.longitude, station.latitude],
        },
      })),
    };
  }, [stations]);

  useEffect(() => {
    // Load the gas station SDF image
    map?.loadImage("/images/gas-station-sdf.png", (error, image) => {
      if (error) {
        console.error("Error loading image", error);
        return;
      }

      if (!map?.hasImage("gas-station-sdf")) {
        map?.addImage("gas-station-sdf", image!, { sdf: true });
      }
    });
  }, [map]);

  useEffect(() => {
    if (selectedStation) {
      map?.flyTo({
        center: [selectedStation.longitude, selectedStation.latitude],
        zoom: 16,
      });
    }
  }, [selectedStation, map]);

  useEffect(() => {
    if (selectedLocation) {
      setMarker([selectedLocation.longitude, selectedLocation.latitude]);
    } else {
      setMarker(undefined);
    }
  }, [selectedLocation, setSelectedStation]);

  useEffect(() => {
    if (stationsInRadiusBounds) {
      map?.fitBounds(stationsInRadiusBounds as LngLatBoundsLike, {
        padding: isDesktop
          ? 75
          : {
              top: 70,
              bottom: 10,
              left: 10,
              right: 10,
            },
      });
    }
  }, [stationsInRadiusBounds, isDesktop, map]);

  return (
    <div className={className}>
      <Map
        initialViewState={{
          bounds: NL_BOUNDING_BOX as LngLatBoundsLike,
        }}
        mapStyle={MAPBOX_STYLE} // TODO: change to production style
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN}
        interactiveLayerIds={[clusterLayer.id!, unclusteredPointLayer.id!]}
        onLoad={onLoad}
        onIdle={onIdle}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onMouseEnter}
        onTouchEnd={onMouseLeave}
        cursor={cursor}
        attributionControl={false}
        touchPitch={false}
        ref={setMap}
        reuseMaps
      >
        <GeocoderControl
          mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN!}
          position="top-left"
          language="nl"
          countries="NL"
          limit={isDesktop ? 5 : 3}
          flyTo={false}
          clearAndBlurOnEsc
          onLocation={onGeocoderLocation}
        />
        <NavigationControl
          position="bottom-right"
          visualizePitch={false}
          showCompass={false}
        />
        <GeolocateControl
          ref={geoControlRef}
          position="bottom-right"
          showUserLocation={false}
          positionOptions={{ enableHighAccuracy: true }}
          onGeolocate={({ coords }) => {
            changeLocation({
              name: "Huidige locatie",
              longitude: coords.longitude,
              latitude: coords.latitude,
            });
          }}
        />
        {marker && (
          <Marker longitude={marker[0]} latitude={marker[1]} anchor="bottom">
            <PinIcon size={20} />
          </Marker>
        )}
        {hoveredStation && hoveredStation._id !== selectedStation?._id && (
          <Popup
            longitude={hoveredStation.longitude}
            latitude={hoveredStation.latitude}
            anchor="bottom"
            closeButton={false}
            className="hover-popup"
          >
            {hoveredStation.name}
          </Popup>
        )}
        {selectedStation && (
          <Popup
            longitude={selectedStation.longitude}
            latitude={selectedStation.latitude}
            anchor="bottom"
            closeButton={false}
            closeOnClick={false}
            closeOnMove={false}
          >
            {selectedStation.name}
          </Popup>
        )}
        <Source
          id="stations-in-radius"
          type="geojson"
          // Passing null doesn't remove the drawn layers, hence we pass an empty feature collection
          // See https://github.com/mapbox/mapbox-gl-js/issues/7016#issuecomment-1279526832
          data={
            stationsInRadiusArea || { type: "FeatureCollection", features: [] }
          }
        >
          <Layer {...stationsInRadiusCircleLayer} />
        </Source>
        <Source
          id="stations"
          type="geojson"
          data={markers}
          generateId={true}
          cluster={true}
          clusterRadius={75}
          clusterMaxZoom={12}
        >
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...unclusteredPointLayer} />
        </Source>
      </Map>
      {children}
    </div>
  );
}

export default StationsMap;
