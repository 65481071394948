import { z } from "zod";

export const StationSchema = z.object({
  _id: z.number(),
  name: z.string(),
  address: z.string(),
  longitude: z.number(),
  latitude: z.number(),
  created_at: z.string().datetime().pipe(z.coerce.date()),
});
export type Station = z.infer<typeof StationSchema>;

export const StationsSchema = z.array(StationSchema);
export type Stations = z.infer<typeof StationsSchema>;

export const GenericGasPriceSchema = z.object({
  euro95: z.number(),
  timestamp: z.string().datetime().pipe(z.coerce.date()),
});

export type GenericGasPrice = z.infer<typeof GenericGasPriceSchema>;

export const GenericGasPricesSchema = z.array(GenericGasPriceSchema);
export type GenericGasPrices = z.infer<typeof GenericGasPricesSchema>;

export const StationGasPriceSchema = z.object({
  gas_station_id: z.number(),
  euro95: z.number(),
});

export type StationGasPrice = z.infer<typeof StationGasPriceSchema>;

export const StationGasPricesSchema = z.array(StationGasPriceSchema);
export type StationGasPrices = z.infer<typeof StationGasPricesSchema>;

export const GeocoderLocationSchema = z.object({
  name: z.string(),
  longitude: z.number(),
  latitude: z.number(),
});
export type GeocoderLocation = z.infer<typeof GeocoderLocationSchema>;
