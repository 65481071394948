"use client";

import { Suspense } from "react";

import { motion, AnimatePresence } from "framer-motion";

import { useUrlState } from "@/hooks/query-state";
import { useMediaQuery } from "@/hooks/use-media-query";

import { ScrollArea } from "@/components/ui/scroll-area";
import StationsMap from "@/components/stations-map";
import Footer from "@/components/footer";

import StationDetails from "@/app/station-details";
import LocationDetails from "@/app/location-details";

function Content() {
  const { selectedStation, selectedLocation } = useUrlState();

  return (
    <AnimatePresence mode="popLayout" initial={false}>
      {selectedStation ? (
        <motion.main
          key="station"
          initial={{ transform: "translateX(-50%)", opacity: 0 }}
          animate={{ transform: "translateX(0)", opacity: 1 }}
          exit={{ transform: "translateX(-50%)", opacity: 0 }}
        >
          <StationDetails className="p-2 md:p-4" station={selectedStation} />
        </motion.main>
      ) : selectedLocation ? (
        <motion.main
          key="location"
          initial={{ transform: "translateX(-50%)", opacity: 0 }}
          animate={{ transform: "translateX(0)", opacity: 1 }}
          exit={{ transform: "translateX(-50%)", opacity: 0 }}
        >
          <LocationDetails className="p-2 md:p-4" location={selectedLocation} />
        </motion.main>
      ) : (
        <motion.main
          key="no-station"
          className="flex grow p-4 pt-8 md:items-center md:pt-0"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="flex flex-col gap-2">
            <h1 className="text-xl font-bold tracking-tight">
              Vergelijk. Bespaar. Tanken maar!
            </h1>
            <p className="text-muted-foreground">
              Zoek een locatie op de kaart om tankstations in de buurt te zien.
            </p>
          </div>
        </motion.main>
      )}
    </AnimatePresence>
  );
}

export default function Page() {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const ScrollElement = isDesktop ? ScrollArea : "div";

  return (
    // Suspense is required for nuqs: https://nuqs.47ng.com/docs/troubleshooting#client-components-need-to-be-wrapped-in-a-suspense-boundary
    <Suspense>
      {/* For why the h-0 is needed, see: https://github.com/w3c/csswg-drafts/issues/6260#issuecomment-837190156 */}
      <div className="flex h-0 grow flex-col-reverse md:flex-row">
        <div className="flex h-0 flex-1 md:h-auto md:w-[26rem] md:flex-none">
          <ScrollElement className="m-2 grow">
            <div className="flex h-full grow flex-col justify-between gap-2">
              <Content />
              <Footer />
            </div>
          </ScrollElement>
        </div>
        <StationsMap className="flex-1" />
      </div>
    </Suspense>
  );
}
