"use client";

import { useMemo } from "react";

import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

import { CardDescription, CardTitle } from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import {
  PriceCard,
  PriceCardTitle,
  PriceCardValue,
  PriceCardContent,
  PriceCardBadge,
} from "@/components/price-card";
import { Skeleton } from "@/components/ui/skeleton";

import type { GenericGasPrices, Station } from "@/types";
import {
  formatChange,
  formatDate,
  formatDateTime,
  formatPrice,
  formatTime,
} from "@/lib/formatters";
import { useStationsAveragePrice, useStationPrices } from "@/hooks/api";

const chartConfig = {
  euro95: {
    label: "Euro95",
  },
} satisfies ChartConfig;

// Current Price Component
interface CurrentPriceProps {
  price: number | undefined;
  averagePrice: number | undefined;
}

function CurrentPrice({ price, averagePrice }: CurrentPriceProps) {
  const changePercentage =
    price && averagePrice
      ? formatChange(((price - averagePrice) / averagePrice) * 100)
      : null;

  return (
    <PriceCard className="mt-8 p-6">
      <PriceCardTitle className="text-base font-medium">Actueel</PriceCardTitle>
      <PriceCardContent>
        {!price || !averagePrice ? (
          <Skeleton className="h-12 w-24" />
        ) : (
          <>
            <PriceCardValue className="text-5xl">
              {formatPrice(price)}
            </PriceCardValue>
            {changePercentage !== "0.0" && (
              <PriceCardBadge
                className={`text-lg font-semibold ${
                  changePercentage?.startsWith("+")
                    ? "text-rose-500"
                    : "text-emerald-500"
                }`}
              >
                {changePercentage}%
              </PriceCardBadge>
            )}
          </>
        )}
      </PriceCardContent>
    </PriceCard>
  );
}

// Price Statistics Component
interface PriceStatisticsProps {
  prices: number[];
  currentPrice: number;
}

function PriceStatistics({ prices, currentPrice }: PriceStatisticsProps) {
  const minPrice = Math.min(...prices);
  const maxPrice = Math.max(...prices);

  const stats = [
    {
      title: "Minimum",
      value: minPrice,
      change: formatChange(((minPrice - currentPrice) / currentPrice) * 100),
      badgeColor: "text-emerald-500",
    },
    {
      title: "Maximum",
      value: maxPrice,
      change: formatChange(((maxPrice - currentPrice) / currentPrice) * 100),
      badgeColor: "text-rose-500",
    },
  ];

  return (
    <div className="mt-8">
      <CardTitle>Statistieken</CardTitle>
      <CardDescription>Prijzen in de afgelopen 24 uur</CardDescription>
      <div className="mt-4 grid grid-cols-2 gap-2">
        {stats.map(({ title, value, change, badgeColor }) => (
          <PriceCard className="px-4 py-3" key={title}>
            <PriceCardTitle>{title}</PriceCardTitle>
            <PriceCardContent>
              {!prices.length || !currentPrice ? (
                <Skeleton className="h-8 w-14" />
              ) : (
                <>
                  <PriceCardValue>{formatPrice(value)}</PriceCardValue>
                  {change !== "0.0" && (
                    <PriceCardBadge className={`text-sm ${badgeColor}`}>
                      {change}%
                    </PriceCardBadge>
                  )}
                </>
              )}
            </PriceCardContent>
          </PriceCard>
        ))}
      </div>
    </div>
  );
}

// Price Chart Component
interface PriceChartProps {
  data: GenericGasPrices;
  period: "day" | "week";
}

function PriceChart({ data, period }: PriceChartProps) {
  const chartData = useMemo(() => {
    // Reverse the array to sort them in ascending order
    return data.toReversed();
  }, [data]);

  return (
    <div className="mt-8">
      <CardTitle>Prijsverloop</CardTitle>
      <CardDescription>
        In de afgelopen {period === "day" ? "24 uur" : "week"}
      </CardDescription>
      {!data.length ? (
        <Skeleton className="mt-4 h-[200px] w-full" />
      ) : (
        <ChartContainer
          config={chartConfig}
          className="mt-4 min-h-[200px] w-full"
        >
          <BarChart
            accessibilityLayer
            data={chartData}
            margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
          >
            <CartesianGrid vertical={false} />
            <YAxis domain={["dataMin - 0.025", "dataMax"]} hide />
            <XAxis
              dataKey="timestamp"
              axisLine={false}
              tickLine={false}
              tickMargin={4}
              interval="preserveStartEnd"
              tickFormatter={(value) =>
                period === "day" ? formatTime(value) : formatDate(value)
              }
            />
            <ChartTooltip
              cursor={false}
              content={
                <ChartTooltipContent
                  hideIndicator
                  labelFormatter={(_, [payload]) =>
                    period === "day"
                      ? formatDateTime(payload.payload.timestamp)
                      : formatDate(payload.payload.timestamp, {
                          includeDayName: true,
                        })
                  }
                />
              }
            />
            <Bar
              dataKey="euro95"
              fill="hsl(var(--primary))"
              activeBar={{ fill: "hsl(var(--muted-foreground))" }}
              radius={2}
            />
          </BarChart>
        </ChartContainer>
      )}
    </div>
  );
}

// Main Component
interface StationDetailsProps {
  className?: string;
  station: Station;
}

function StationDetails({ className, station }: StationDetailsProps) {
  const { data: averagePrice } = useStationsAveragePrice();
  const { data: dayPrices = [] } = useStationPrices(station._id, "day");
  const { data: weekPrices = [] } = useStationPrices(station._id, "week");

  const currentPrice = dayPrices.length > 0 ? dayPrices[0].euro95 : undefined;

  return (
    <div className={className}>
      <h1 className="scroll-m-20 text-2xl font-semibold tracking-tight">
        {station.name}
      </h1>
      <span className="leading-7">{station.address}</span>

      <div className="mt-8">
        <span className="rounded-lg bg-white px-3 py-2 text-base font-semibold text-emerald-700">
          Euro95
        </span>
      </div>

      <CurrentPrice price={currentPrice} averagePrice={averagePrice?.euro95} />
      <PriceStatistics
        prices={dayPrices.map((price) => price.euro95)}
        currentPrice={currentPrice ?? 0}
      />
      <PriceChart data={dayPrices} period="day" />
      <PriceChart data={weekPrices} period="week" />
    </div>
  );
}

export default StationDetails;
