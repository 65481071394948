import { useMemo } from "react";

import { LoaderCircle } from "lucide-react";

import { Button } from "@/components/ui/button";

import {
  useStationsAveragePrice,
  useStationsInRadius,
  useStationsLastPrices,
} from "@/hooks/api";
import { cn } from "@/lib/utils";
import { formatDistance, formatPrice } from "@/lib/formatters";
import { useUrlState } from "@/hooks/query-state";
import type { GeocoderLocation } from "@/types";

type LocationDetailsProps = {
  location: GeocoderLocation;
  className?: string;
};

function LocationDetails({ location, className }: LocationDetailsProps) {
  const radius = 5;
  const { stationsInRadius } = useStationsInRadius(radius);
  const { setSelectedStation } = useUrlState();

  const { data: lastPrices, isLoading: isLastPricesLoading } =
    useStationsLastPrices();
  const { data: averagePrice, isLoading: isAveragePriceLoading } =
    useStationsAveragePrice();
  const isLoading = isLastPricesLoading || isAveragePriceLoading;

  const sortedStations = useMemo(() => {
    if (!stationsInRadius || !lastPrices) {
      return [];
    }

    const stationsWithPrices = stationsInRadius.map(({ station, distance }) => {
      const lastPrice = lastPrices.find(
        (p) => p.gas_station_id === station._id,
      );

      return {
        station,
        distance,
        price: lastPrice?.euro95,
      };
    });

    return stationsWithPrices.sort(
      (a, b) => (a.price ?? Infinity) - (b.price ?? Infinity),
    );
  }, [stationsInRadius, lastPrices]);

  return (
    <div className={cn("flex flex-col", className)}>
      <div className="text-lg font-semibold tracking-tight">Tankstations</div>
      <div className="text-muted-foreground">
        Binnen {radius} km van {location.name}
      </div>
      <div className="mt-4 flex flex-col gap-2">
        {isLoading ? (
          <div className="flex items-center justify-center p-8">
            <LoaderCircle className="h-6 w-6 animate-spin" />
          </div>
        ) : (
          sortedStations.map(({ station, distance, price }, i) => (
            <Button
              key={station._id}
              variant="ghost"
              size="lg"
              className="flex justify-between pl-2 pr-4"
              onClick={() => setSelectedStation(station)}
            >
              <div className="flex flex-col items-start">
                <span className="flex items-center gap-2">
                  <span className="min-w-[1rem] text-right text-muted-foreground">
                    {i + 1}.
                  </span>
                  <span>{station.name}</span>
                  <span className="text-muted-foreground">
                    {formatDistance(distance)}
                  </span>
                </span>
              </div>
              {price && averagePrice && (
                <span
                  className={`text-sm ${
                    price < averagePrice.euro95
                      ? "text-emerald-500"
                      : "text-rose-500"
                  }`}
                >
                  {formatPrice(price, true)}
                </span>
              )}
            </Button>
          ))
        )}
      </div>
    </div>
  );
}

export default LocationDetails;
