import { useControl, ControlPosition } from "react-map-gl";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import MapboxGeocoder, {
  GeocoderOptions,
  Result,
  Results,
} from "@mapbox/mapbox-gl-geocoder";

import { GeocoderLocation } from "@/types";

type GeocoderControlProps = Omit<
  GeocoderOptions,
  "accessToken" | "mapboxgl" | "marker"
> & {
  mapboxAccessToken: string;
  position: ControlPosition;
  onClear?: () => void;
  onLoading?: (query: string) => void;
  onLocation?: (location: GeocoderLocation) => void;
  onResults?: (results: Results) => void;
  onResult?: ({ result }: { result: Result }) => void;
  onError?: (error: string) => void;
};

const noop = () => {};

export default function GeocoderControl(props: GeocoderControlProps) {
  // @ts-expect-error (TS2344)
  useControl<MapboxGeocoder>(
    () => {
      const ctrl = new MapboxGeocoder({
        ...props,
        marker: false,
        accessToken: props.mapboxAccessToken,
      });
      ctrl.on("clear", props.onClear || noop);
      ctrl.on("loading", props.onLoading || noop);
      ctrl.on("results", props.onResults || noop);
      ctrl.on("error", props.onError || noop);
      ctrl.on("result", (event: { result: Result }) => {
        props.onResult?.(event);

        const { result } = event;
        const position =
          result &&
          (result.center ||
            (result.geometry?.type === "Point" && result.geometry.coordinates));

        props.onLocation?.({
          name: result.place_name,
          longitude: position[0],
          latitude: position[1],
        });
      });
      return ctrl;
    },
    {
      position: props.position,
    },
  );

  return null;
}
