import * as React from "react";

function PinIcon({ size = 20 }) {
  return (
    <span className="relative flex justify-center">
      <span className="absolute top-2 inline-flex h-6 w-6 animate-ping-slow rounded-full bg-cyan-500 opacity-50"></span>
      <svg
        height={size}
        viewBox="0 0 24 24"
        className="relative fill-cyan-600 stroke-white stroke-1"
      >
        <path d="M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9C20.1,15.8,20.2,15.8,20.2,15.7z" />
      </svg>
    </span>
  );
}

export default React.memo(PinIcon);
