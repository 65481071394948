"use client";

import { useQueryState, parseAsJson } from "nuqs";

import { GeocoderLocationSchema, StationSchema } from "@/types";
import { useMemo } from "react";

function useSelectedLocation() {
  return useQueryState(
    "location",
    parseAsJson(GeocoderLocationSchema.parse).withOptions({ history: "push" }),
  );
}

function useSelectedStation() {
  return useQueryState(
    "station",
    parseAsJson(StationSchema.parse).withOptions({ history: "push" }),
  );
}

export function useUrlState() {
  const [selectedLocation, changeSelectedLocation] = useSelectedLocation();
  const [selectedStation, changeSelectedStation] = useSelectedStation();

  // Clear the selected station when the location changes
  const setSelectedLocation = useMemo(
    () =>
      (...params: Parameters<typeof changeSelectedLocation>) => {
        changeSelectedStation(null, { history: "replace" });
        changeSelectedLocation(...params);
      },
    [changeSelectedLocation, changeSelectedStation],
  );

  // Clear the selected location when the station changes
  const setSelectedStation = useMemo(
    () =>
      (...params: Parameters<typeof changeSelectedStation>) => {
        changeSelectedLocation(null, { history: "replace" });
        changeSelectedStation(...params);
      },
    [changeSelectedStation, changeSelectedLocation],
  );

  return useMemo(
    () => ({
      selectedLocation,
      selectedStation,
      setSelectedLocation,
      setSelectedStation,
    }),
    [
      selectedLocation,
      selectedStation,
      setSelectedLocation,
      setSelectedStation,
    ],
  );
}
