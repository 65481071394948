import { useState, useLayoutEffect } from "react";
import { useMediaQuery as originalHook } from "usehooks-ts";

// Override the original hook to avoid hydration issues
// For more info see: https://stackoverflow.com/a/78447136
function useMediaQuery(query: string) {
  const queryResult = originalHook(query);
  const [result, setResult] = useState<boolean | null>(null);

  useLayoutEffect(() => {
    if (queryResult !== null) {
      setResult(queryResult);
    }
  }, [query, queryResult]);

  return result;
}

export { useMediaQuery };
