export function formatPrice(price: number, showCurrency = false) {
  return price.toLocaleString("nl-NL", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: showCurrency ? "currency" : "decimal",
    currency: "EUR",
  });
}

export function formatDate(date: Date, options?: { includeDayName?: boolean }) {
  return date.toLocaleDateString("nl-NL", {
    month: "short",
    day: "numeric",
    weekday: options?.includeDayName ? "long" : undefined,
  });
}

export function formatTime(time: Date) {
  return time.toLocaleTimeString("nl-NL", {
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatDateTime(date: Date) {
  return date.toLocaleDateString("nl-NL", {
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export function formatChange(change: number) {
  const result = change.toFixed(1);
  return change > 0 ? `+${result}` : result;
}

export function formatDistance(distance: number) {
  return distance < 1
    ? `${(distance * 1000).toFixed(0)} m`
    : `${distance.toFixed(1)} km`;
}
