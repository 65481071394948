import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { circle, booleanPointInPolygon, distance, bbox } from "@turf/turf";

import {
  GenericGasPriceSchema,
  GenericGasPricesSchema,
  StationGasPricesSchema,
  StationsSchema,
} from "@/types";
import type {
  GenericGasPrice,
  GenericGasPrices,
  StationGasPrices,
  Stations,
} from "@/types";

import { useUrlState } from "@/hooks/query-state";

const API_URL =
  process.env.NODE_ENV === "production"
    ? "https://api.pompwijzer.nl/api"
    : "/api";

export function useStations() {
  return useQuery<Stations>({
    queryKey: ["stations"],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/stations`);
      return StationsSchema.parse(await response.json());
    },
  });
}

export function useStationsInRadius(radius: number = 5) {
  const { data: stations = [] } = useStations();
  const { selectedLocation } = useUrlState();

  return useMemo(() => {
    if (!selectedLocation) {
      return {
        stationsInRadiusArea: null,
        stationsInRadiusBounds: null,
        stationsInRadius: [],
      };
    }

    // Note to future self:
    //  I benchmarked this approach vs. calculating the distance for each station
    //  first then filtering (I even tried combining the 2 loops into 1 using flatMap).
    //  I found no difference in performance, so I went with the more readable version.
    const position = [selectedLocation.longitude, selectedLocation.latitude];
    const stationsInRadiusArea = circle(position, radius);
    const stationsInRadiusBounds = bbox(stationsInRadiusArea);
    const stationsInRadius = stations
      .filter((station) =>
        booleanPointInPolygon(
          [station.longitude, station.latitude],
          stationsInRadiusArea,
        ),
      )
      .map((station) => ({
        station,
        distance: distance([station.longitude, station.latitude], position),
      }));

    return {
      stationsInRadiusArea,
      stationsInRadiusBounds,
      stationsInRadius,
    };
  }, [radius, stations, selectedLocation]);
}

export function useStationPrices(stationId: number, period: "day" | "week") {
  return useQuery<GenericGasPrices>({
    queryKey: ["stations", stationId, "prices", period],
    queryFn: async () => {
      const response = await fetch(
        `${API_URL}/stations/${stationId}/prices?period=${period}`,
      );
      return GenericGasPricesSchema.parse(await response.json());
    },
  });
}

export function useStationsLastPrices() {
  return useQuery<StationGasPrices>({
    queryKey: ["stations/last-prices"],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/stations/last-prices`);
      return StationGasPricesSchema.parse(await response.json());
    },
  });
}

export function useStationsAveragePrice() {
  return useQuery<GenericGasPrice>({
    queryKey: ["stations/average-price"],
    queryFn: async () => {
      const response = await fetch(`${API_URL}/stations/average-price`);
      return GenericGasPriceSchema.parse(await response.json());
    },
  });
}
