import React from "react";

import { cn } from "@/lib/utils";

const PriceCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col rounded-lg border", className)}
    {...props}
  />
));
PriceCard.displayName = "PriceCard";

const PriceCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center justify-between", className)}
    {...props}
  />
));
PriceCardContent.displayName = "PriceCardContent";

const PriceCardTitle = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn("text-sm tracking-tight text-muted-foreground", className)}
    {...props}
  />
));
PriceCardTitle.displayName = "PriceCardTitle";

const PriceCardValue = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3 ref={ref} className={cn("text-3xl font-bold", className)} {...props} />
));
PriceCardValue.displayName = "PriceCardValue";

const PriceCardBadge = React.forwardRef<
  HTMLHeadingElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3 ref={ref} className={cn(className)} {...props} />
));
PriceCardBadge.displayName = "PriceCardBadge";

export {
  PriceCard,
  PriceCardContent,
  PriceCardTitle,
  PriceCardValue,
  PriceCardBadge,
};
